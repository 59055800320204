// make all event listeners passive by default
import 'default-passive-events';

import type { SsrProps } from '@/core/features/app/ssr-props';
import type { AppProps as NextSsrProps } from 'next/app';

import React from 'react';

import dynamic from 'next/dynamic';

import '@/assets/global-styles/colors.css';
import '@/assets/global-styles/util-classes.css';
import '@/assets/global-styles/z-index.css';
import { ErrorBoundaryWrapper } from '@/core/features';
import { createQueryClient } from '@/core/features/a-dynamic-page/dynamic-page-query-client';
import AppContextWrapper from '@/core/features/app/app-context-wrapper';
import { mapSsrProps } from '@/core/features/app/ssr-props';
import LazyHydrateOn from '@/core/features/lazy/lazy-hydrate-on';
import '@/core/features/logger/console';
import HeadDynamic from '@/core/features/page/head-dynamic';
import { Hydrate, QueryClientProvider } from '@/core/features/react-query/react-query-service';
import useWireframeConsentBannerVisibleListener from '@/core/features/wireframe/wireframe-manipulation/consent-banner/use-wireframe-consent-banner-visible';
import AppSplashWrapper from '@/features/app-splash/app-splash-wrapper';

const HiddenMenu = dynamic(
    () => import(/* webpackChunkName: "HiddenMenu" */ '@/core/features/hidden-menu/hidden-menu'),
);

export default function App({ Component, pageProps, router }: NextSsrProps<SsrProps>) {
    const [queryClient] = React.useState(createQueryClient(router.asPath));

    const handledPageProps = mapSsrProps(router.asPath, pageProps);
    const { dehydratedState, isStaticRoute, isWidget } = handledPageProps;

    const isCookieConsentBannerVisible = useWireframeConsentBannerVisibleListener(
        handledPageProps.cookiesMapped,
        handledPageProps.okResponse,
    );

    if (isStaticRoute || isWidget) {
        return (
            <QueryClientProvider client={queryClient}>
                <Hydrate state={dehydratedState}>
                    <ErrorBoundaryWrapper>
                        <AppContextWrapper {...handledPageProps}>
                            <HeadDynamic {...handledPageProps} />
                            {pageProps.isInternalIp && !pageProps.isHiddenMenuDisabled && (
                                <HiddenMenu {...handledPageProps} />
                            )}
                            <Component {...pageProps} />
                        </AppContextWrapper>
                    </ErrorBoundaryWrapper>
                </Hydrate>
            </QueryClientProvider>
        );
    }

    return (
        <QueryClientProvider client={queryClient}>
            <Hydrate state={dehydratedState}>
                <ErrorBoundaryWrapper>
                    <AppContextWrapper {...handledPageProps}>
                        <HeadDynamic {...handledPageProps} />
                        {pageProps.isInternalIp && !pageProps.isHiddenMenuDisabled && (
                            <HiddenMenu {...handledPageProps} />
                        )}
                        <AppSplashWrapper {...handledPageProps} />
                        <LazyHydrateOn
                            condition={!isCookieConsentBannerVisible}
                            htmlRootTag={'main'}
                        >
                            <Component {...handledPageProps} />
                        </LazyHydrateOn>
                    </AppContextWrapper>
                </ErrorBoundaryWrapper>
            </Hydrate>
        </QueryClientProvider>
    );
}
