import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { useDynamicPageQueryData } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import { layoutIdAtom } from '@/core/features/app/app-atoms';
import { useHydratePrefetchSetting } from '@/core/features/link/prefetch-setting-state';
import { atom, useAtomValue, useHydrateAtoms, useSetAtom } from '@/core/features/store/atom-store';

const metaDataStateAtom = atom<DynamicPageMeta | null>(null);

export const useMetaDataState = () => useAtomValue(metaDataStateAtom);

export const useHydrateMetaDataState = ({ defaultMeta }: { defaultMeta: DynamicPageMeta | null }) => {
    const { data, isFetching } = useDynamicPageQueryData();

    const setMetaDataState = useSetAtom(metaDataStateAtom);
    const setLayoutId = useSetAtom(layoutIdAtom);

    const meta = data?.response?.meta ?? defaultMeta;

    useHydrateAtoms([
        [metaDataStateAtom, meta ?? null],
        [layoutIdAtom, meta?.layoutId ?? null],
    ]);
    useHydratePrefetchSetting(meta?.settings);

    // update on metaData change
    React.useEffect(() => {
        if (isFetching) {
            return;
        }
        setMetaDataState(meta ?? null);
        setLayoutId(meta?.layoutId ?? null);
    }, [meta, isFetching, setMetaDataState, setLayoutId]);
};
