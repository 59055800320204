import React from 'react';

import { atom, useAtom } from 'jotai';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { isNestedComponent } from '@/core/features/a-component/services/component-service';
import { useDynamicPageQueryData } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-query';
import { useClientUrl } from '@/core/features/app/app-atoms';
import { getPageComponentIndex } from '@/core/features/scroll/page-scroll-restoration/page-scroll-position-service';

const forceRenderPageComponentIndexAtom = atom<number>(-1);

export const useForceRenderPageComponentIndex = () => useAtom(forceRenderPageComponentIndexAtom);

export function useDynamicPageComponentIndex(componentIri: null | string): number {
    const { data: dynamicPageData } = useDynamicPageQueryData();

    const components = dynamicPageData?.response?.components;
    const pageComponentIndex = React.useMemo(() => {
        return !components || !componentIri
            ? -2
            : components
                  .flatMap((component) => (isNestedComponent(component) ? component.attributes.components : component))
                  .findIndex((component) => getComponentId(component['@id']) === getComponentId(componentIri));
    }, [components, componentIri]);

    return pageComponentIndex;
}

export function useHydratePageComponentIndexSessionStorage() {
    const [clientUrl] = useClientUrl();
    const [_, setForceRenderedPageComponentIndex] = useForceRenderPageComponentIndex();

    React.useEffect(() => {
        const currentPageComponentIndex = getPageComponentIndex(clientUrl);
        if (!currentPageComponentIndex || currentPageComponentIndex < 1) {
            return;
        }
        setForceRenderedPageComponentIndex(currentPageComponentIndex);
    }, [clientUrl, setForceRenderedPageComponentIndex]);
}
