/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-this */

import type { BrowserStorageOptions } from '@/core/features/store/browser-storage';

import BrowserStorage from '@/core/features/store/browser-storage';

type StorageName =
    | 'conversion_tracking'
    | 'debug_feature_flags'
    | 'login'
    | 'page_component_index'
    | 'scroll'
    | 'scroll_horizontal'
    | 'search'
    | 'spot_tile_active_tab'
    | 'swiper_slide'
    | 'unique_general_tracking'
    | 'wishlist'
    | 'wishlist_change';

/* eslint-disable fp/no-class */
export default class SessionStorage<T> extends BrowserStorage<T> {
    constructor(storeName: StorageName, options?: BrowserStorageOptions) {
        super('sessionStorage', storeName, options);
    }
}
