import dynamic from 'next/dynamic';

/**
 * Grouped Eager Core Components Bundle/Chunk
 * Add to this chunk if components are rather small in size but loaded early / in first view
 */
export const Button = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/button/button'));
export const ButtonWrapper = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/button/button-wrapper'));
export const Headline = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/headline/headline'));
export const KeyFact = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */'@/core/features/key-fact/key-fact'));
export const Link = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/features/link/link'));
export const ConditionalLinkWrapper = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/features/link/conditional-link-wrapper/conditional-link-wrapper'));
export const LazyComponent = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/features/lazy/lazy-component'));
export const HtmlTextWrapper = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/html-text-wrapper/html-text-wrapper'));
export const ErrorBoundaryWrapper = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/features/error-boundary/error-boundary-wrapper'));
export const LoadingLayer = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/loading/loading-layer'));
export const WishlistButton = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/features/wishlist/wishlist-button/wishlist-button'));
export const KeyFacts = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/key-facts/key-facts'));
export const KeyFactsHorizontal = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/key-facts-horizontal/key-facts-horizontal'));
export const InfoIconModal = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/features/info-icon-modal/info-icon-modal'));
export const InfoIconTooltip = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/features/info-icon-modal/info-icon-tooltip'));
export const InfoIcon = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/assets/icons/info'));
export const Columns = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/columns/columns'));
export const Badges = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */'@/core/components/badges/badges'));
export const Badge = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */'@/core/components/badges/badge'));
export const Divider = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */'@/core/components/divider/divider'));

export const LoopedLoadingBar = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/loading/looped-loading-bar'));
export const LoadingSpinnerIcon = dynamic(()=> import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/loading/loading-spinner'))
export const ShimmerBox = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEager" */ '@/core/components/shimmer/shimmer-box'));

export const KeyFactsDesktop = dynamic(() => import(/* webpackChunkName: "CoreFeaturesEagerDesktop" */ '@/core/components/key-facts/key-facts-desktop'));

/**
 * Individual Core Component Chunks
 * Create an individual Chunk for Components that are rather large
 */

export const Image = dynamic(() => import(/* webpackChunkName: "Image" */ '@/core/features/image-v2/image'));
export const ResponsiveImage = dynamic(() => import(/* webpackChunkName: "Image" */ '@/core/features/image-v2/responsive-image'));
export const IconImage = dynamic(() => import(/* webpackChunkName: "Image" */ '@/core/features/image-v2/icon-image'));
export const FullscreenImage = dynamic(() => import(/* webpackChunkName: "Image" */ '@/core/features/image-v2/fullscreen-image'));
export const RawImage = dynamic(() => import(/* webpackChunkName: "Image" */ '@/core/features/image-v2/raw-image'));

// Chunk: Tooltip
export const Tooltip = dynamic(() => import(/* webpackChunkName: "Tooltip" */ '@/core/features/tooltip/tooltip'));
export const TooltipTextBox = dynamic(() => import(/* webpackChunkName: "Tooltip" */ '@/core/features/tooltip/tooltip-text-box'));

// Chunk: Swiper
export const SwiperWrapper = dynamic(() => import(/* webpackChunkName: "Swiper" */ '@/core/features/swiper/swiper-wrapper/swiper-wrapper'));
export const MediaSwiper = dynamic(() => import(/* webpackChunkName: "Swiper" */ '@/core/features/swiper/media-swiper/media-swiper'));
export const ContentBoxSwiperDesktop = dynamic(() => import(/* webpackChunkName: "ContentBoxSwiperDesktop" */ '@/core/features/swiper/content-box-swiper-desktop/content-box-swiper-desktop'));

// Chunk: DatePicker
export const DatePicker = dynamic(() => import(/* webpackChunkName: "DatePicker" */ '@/core/features/date-picker/date-picker'));
export const DatePickerHorizontal = dynamic(() => import(/* webpackChunkName: "DatePicker" */ '@/core/features/date-picker/date-picker-horizontal/date-picker-horizontal'));
export const DatePickerVertical = dynamic(() => import(/* webpackChunkName: "DatePicker" */ '@/core/features/date-picker/date-picker-vertical/date-picker-vertical'));

// Chunk: Modal (overlay included)
export const Modal = dynamic(() => import(/* webpackChunkName: "Modal" */ '@/core/features/modals/modal/modal'));
export const ModalWithText = dynamic(() => import(/* webpackChunkName: "Modal" */ '@/core/features/modals/modal/modal-with-text'));
export const ModalWithMediaSwiper = dynamic(() => import(/* webpackChunkName: "ModalWithMediaSwiper" */ '@/core/features/modals/modal/modal-with-media-swiper'));
export const FullscreenOverlay = dynamic(() => import(/* webpackChunkName: "Modal" */ '@/core/features/modals/fullscreen-overlay/fullscreen-overlay'));
export const SlideUpOverlay = dynamic(() => import(/* webpackChunkName: "Modal" */ '@/core/features/modals/slide-up-overlay/slide-up-overlay'));

// Chunk: CtaList
export const CtaList = dynamic(() => import(/* webpackChunkName: "CtaList" */ '@/core/components/cta-list/cta-list'));
export const CtaListOverlay = dynamic(() => import(/* webpackChunkName: "CtaListOverlay" */ '@/core/components/cta-list/cta-list-overlay'));

// Chunk: ErrorPage
export const ErrorPage = dynamic(() => import(/* webpackChunkName: "ErrorPage" */ '@/core/features/error-page/error-page'));

// ScrollToComponentLink
export const ScrollToComponentLink = dynamic(
    () => import(/* webpackChunkName: "ScrollToComponentLink" */ '@/core/features/link/scroll-to-component-link'),
);
/**
 * Grouped Core Components not worth creating a chunk for (re-exported)
 */