import type { DynamicPageMeta } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';

import React from 'react';

import { atom, useAtomValue, useHydrateAtoms, useSetAtom } from '@/core/features/store/atom-store';

// we want undefined, so Nextjs does not complain that we set prefetch={true} as this is the default
// see: https://nextjs.org/docs/messages/prefetch-true-deprecated
type PrefetchType = false | undefined;

const prefetchAtom = atom<PrefetchType>(undefined);

export const usePrefetchSetting = () => useAtomValue(prefetchAtom);

export const useHydratePrefetchSetting = (settings?: DynamicPageMeta['settings']) => {
    const setPrefetchSetting = useSetAtom(prefetchAtom);

    // hydrate the atom initially
    useHydrateAtoms([[prefetchAtom, getPrefetchSetting(settings)]]);

    // update on change
    React.useEffect(() => {
        setPrefetchSetting(getPrefetchSetting(settings));
    }, [settings, setPrefetchSetting]);
};

const getPrefetchSetting = (settings?: DynamicPageMeta['settings']): PrefetchType => {
    return settings?.prefetch === false ? false : undefined;
};
