import type { CookiesMapped } from '@/core/features/cookies/cookies-service';
import type { ConsentCallbackEvent } from '@/core/features/wireframe/wireframe-types';

import React from 'react';

import { WIREFRAME_CONSENT_COOKIE_NAME } from '@packages/wireframe/src/constants';

import { IS_NODE_ENV_DEV, IS_SERVER } from '@/constants/env';
import { useHydrateAtoms, useSetAtom } from '@/core/features/store/atom-store';
import { atom, useAtomValue } from '@/core/features/store/atom-store';

const cookieConsentBannerVisibleAtom = atom<boolean>(false);
export const useCookieConsentBannerVisibleState = () => useAtomValue(cookieConsentBannerVisibleAtom);

export const useHydrateWireframeConsentBannerVisibleState = (cookiesMapped: CookiesMapped, okResponse: boolean) => {
    const setWireframeConsentBannerVisible = useSetAtom(cookieConsentBannerVisibleAtom);
    const isConsentBannerVisible = useWireframeConsentBannerVisibleListener(cookiesMapped, okResponse);

    useHydrateAtoms([[cookieConsentBannerVisibleAtom, !cookiesMapped.c24consent && okResponse]]);

    React.useEffect(() => {
        setWireframeConsentBannerVisible(isConsentBannerVisible);
    }, [isConsentBannerVisible, setWireframeConsentBannerVisible]);

    React.useEffect(() => {
        handleBraveBrowser(cookiesMapped.c24consent);
    }, [cookiesMapped.c24consent]);
};

export default function useWireframeConsentBannerVisibleListener(cookiesMapped: CookiesMapped, okResponse: boolean) {
    const [isVisible, setVisible] = React.useState(!cookiesMapped.c24consent && okResponse);
    const updateVisible = React.useCallback(
        (event: unknown) => {
            const result = event as ConsentCallbackEvent;
            setVisible(!result.detail.c24consent && okResponse);
        },
        [setVisible, okResponse],
    );

    React.useEffect(() => {
        window.addEventListener(WIREFRAME_CONSENT_COOKIE_NAME, updateVisible, { once: true });

        return () => {
            window.removeEventListener(WIREFRAME_CONSENT_COOKIE_NAME, updateVisible);
        };
    }, [updateVisible]);

    return isVisible;
}

/**
 * Brave Browser removes the Cookie Consent
 * causing the page to be unresponsive as it does not hydrate.
 * Therefore set a functional consent if the user is using Brave Browser.
 */
const handleBraveBrowser = (consentType: CookiesMapped['c24consent']) => {
    if (consentType) {
        return; // do nothing if consent was alreay given
    }
    if (IS_SERVER) {
        return; // do nothing on server;
    }
    if (IS_NODE_ENV_DEV) {
        return; // do nothing in development
    }
    try {
        // not brave
        if (!('brave' in window.navigator)) {
            return;
        }
        // is brave, give functional consent as user would intend
        window.Check24.cookieBanner?.c24consent.giveConsent('f');
        location.reload();
    } catch (e) {}
};
