import { INDI_API_HOST } from '@/constants/env';
import { JsonldRequestHeaders } from '@/constants/request-headers';
import { TIME_10M } from '@/core/utils/time';

export const API_CLIENT_DEFAULT_CONFIG = {
    BASE: INDI_API_HOST,
    HEADERS: JsonldRequestHeaders,
};

export const DEFAULT_QUERY_STALE_TIME = TIME_10M;
