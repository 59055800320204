import { cookiesMappedAtom } from '@/core/features/app/app-atoms';
import { getDeviceoutputFromCookies, getDeviceoutputMapped } from '@/core/features/cookies/cookies-service';
import { atom, useAtomValue } from '@/core/features/store/atom-store';

export function useDeviceoutput() {
    return useAtomValue(deviceoutputAtom);
}

const deviceoutputAtom = atom((get) => {
    const cookiesMapped = get(cookiesMappedAtom);

    const deviceoutput = getDeviceoutputFromCookies(cookiesMapped);
    const deviceoutputMapped = getDeviceoutputMapped(deviceoutput);
    const isDesktopView = ['desktop', 'tablet', 'tabletapp'].includes(deviceoutput);
    const isApp = ['app', 'tabletapp'].includes(deviceoutput);

    return { deviceoutput, deviceoutputMapped, isApp, isDesktopView };
});
