// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from '@sentry/nextjs';

// Sentry.init({
//     // Setting this option to true will print useful information to the console while you're setting up Sentry.
//     debug: false,
//     dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
//     environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,

//     // Add optional integrations for additional features
//     integrations: [
//         Sentry.replayIntegration({
//             // Additional SDK configuration goes in here, for example:
//             blockAllMedia: true,
//             maskAllText: true,
//         }),
//     ],

//     // Define how likely Replay events are sampled.
//     // This sets the sample rate to be 10%. You may want this to be 100% while
//     // Define how likely Replay events are sampled when an error occurs.
//     replaysOnErrorSampleRate: process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ERROR_SAMPLE_RATE
//         ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_ERROR_SAMPLE_RATE)
//         : 0.1,

//     // in development and sample at a lower rate in production
//     replaysSessionSampleRate: process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE
//         ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_REPLAYS_SESSION_SAMPLE_RATE)
//         : 0.1,

//     // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
//     tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE
//         ? parseFloat(process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE)
//         : 0.1,
// });
;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"FE v229.1"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";