export const isApiPath = (url: string) => url.includes('/api/');

export const isStaticPath = (url: string) => isStaticFilePath(url) || isApiPath(url);

export const isStaticFilePath = (url: string) => STATIC_FILE_PATHS.some((path) => url.includes(path));

const STATIC_FILE_PATHS = [
    'validate-user',
    'google-tag-manager',
    'googleb0fac867ad545f38.html',
    '/wireframe',
    '/assets',
    '/_next/',
    '/images/',
    '/sw.js',
    '/favicon',
    'workbox',
    '.json',
    '.png',
    '/robots.txt',
    '.css',
    '.js',
    '/500',
    'sitemap',
    '.well-known',
];
