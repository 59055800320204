import logger from '@/core/features/logger/logger';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#examples
export const circularStringify = (value: unknown): string => JSON.stringify(value, getCircularReplacer());

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: unknown, value: unknown) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

export const parseSafe = (value: null | string | undefined): Object => {
    try {
        if (typeof value !== 'string') {
            return {};
        }
        return JSON.parse(value);
    } catch (error) {
        logger.error(`Failed to parse value of: ${value}`);
        return {};
    }
};
