import { INDI_API_HOST_TEMPLATE, NEXT_PUBLIC_HOST_NAME } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';

export type SearchParams<T = string> = Record<string, T | undefined>;

export const getURL = (url: string, hostName = NEXT_PUBLIC_HOST_NAME): URL => {
    return url.indexOf('http') === 0 ? new URL(url) : new URL(`https://${hostName}` + url);
};

export const getUrlByPath = (urlPath: string, hostName = NEXT_PUBLIC_HOST_NAME) => {
    return new URL(`https://${hostName}${urlPath}`);
};

export const getApiUrlByTBSName = (tbsName: string) => {
    if (!INDI_API_HOST_TEMPLATE) {
        logger.error('INDI_API_HOST_TEMPLATE is missing');
        return;
    }
    return INDI_API_HOST_TEMPLATE.replace('<Name>', tbsName.toLowerCase());
};

export const getSearchParameterByName = (url: URL | string, name: string): null | string => {
    try {
        const parsedUrl = url instanceof URL ? url : getURL(url);
        return parsedUrl.searchParams.get(name);
    } catch (error) {
        logger.error(`Error in getSearchParameterByName ${name}`, {
            additionalData: `error: ${error}`,
            url: url instanceof URL ? url.toString() : url,
        });

        return null;
    }
};

export const urlSearchParamsToObject = <T = string>(searchParams: URLSearchParams): SearchParams<T> => {
    const entries = searchParams.entries() as IterableIterator<[string, T]>;
    return Object.fromEntries(entries);
};

export const getUrlPath = (url: string): string => {
    const URL = getURL(url);
    return URL.pathname;
};

export const getUrlSearchParams = (url: string): URLSearchParams => {
    const URL = getURL(url);
    return new URLSearchParams(URL.search);
};

export const getUrlPathWithSearch = (url: URL | string): string => {
    const URL = typeof url === 'string' ? getURL(url) : url;

    const transformedUrl = URL.href.replace(URL.origin, '');

    if (transformedUrl === '/?') {
        return '/';
    }

    return transformedUrl;
};

export const removeAllSearchParams = (url: string) => {
    return url.split('?')[0] ?? url;
};

export const removeTrailingQuestionmark = (url: string) => {
    // remove trailing question mark;
    return url.replace(new RegExp('\\?$'), '');
};

export const mergeQueryParams = (...searchParamsCollection: URLSearchParams[]): URLSearchParams => {
    const newParams = new URLSearchParams();
    searchParamsCollection.forEach((searchParams) => {
        const searchParamsObject = urlSearchParamsToObject(searchParams);
        Object.entries(searchParamsObject).forEach(([key, value]) => {
            const serializedValue = Array.isArray(value) ? value.join(',') : value;
            newParams.set(key, serializedValue ?? '');
        });
    });
    return newParams;
};

export const filterUrlParams = (urlParams: URLSearchParams, removedUrlParams: string[]): URLSearchParams => {
    removedUrlParams.forEach((removeParam) => {
        urlParams.delete(removeParam);
    });

    return urlParams;
};

// {a:1, b:2} => "?a=1&b=2"
export const searchParamsObjectToString = (
    searchParams: SearchParams,
    options?: { withQuestionmark?: true },
): string => {
    searchParams.toString();
    const url = getURL('/');
    Object.entries(searchParams).forEach(([key, value]) => {
        url.searchParams.set(key, value ? String(value) : '');
    });
    if (options?.withQuestionmark) {
        return url.search;
    }
    return url.search.replace('?', '');
};

export const buildUrlWithSearch = (urlPath: string, searchParams?: SearchParams): URL => {
    const url = getURL(urlPath);
    if (searchParams) {
        Object.entries(searchParams).forEach(([key, value]) => {
            url.searchParams.set(key, value ? String(value) : '');
        });
    }
    return url;
};

export const updateSearchParams = (url: string, params: SearchParams): string => {
    try {
        const URL = getURL(url);

        // eslint-disable-next-line fp/no-mutation
        URL.search = '';

        Object.entries(params).forEach(([key, value]) => {
            if (!value) {
                return;
            }
            if (value.length === 0) {
                URL.searchParams.delete(key);
                return;
            }
            URL.searchParams.set(key, value);
        });
        return URL.toString();
    } catch (error) {
        logger.error('Error in updateSearchParams', {
            additionalData: `params: ${params}, error: ${error}`,
            url,
        });

        return url;
    }
};

export const hasSearchParamChanged = (currentUrl: string, nextUrl: string, searchParamName: string) => {
    const currentSearchParams = getURL(currentUrl).searchParams.get(searchParamName);
    const nextSearchParams = getURL(nextUrl).searchParams.get(searchParamName);
    return currentSearchParams !== nextSearchParams;
};

export const isExternalUrl = (url: string) => {
    return NEXT_PUBLIC_HOST_NAME && !url.includes(NEXT_PUBLIC_HOST_NAME) && url.includes('http');
};
