/* eslint-disable fp/no-mutation */
import type { FeatureFlags } from '@/core/features/store/feature-flags-store';
import type { WireframeCookieConsentType } from '@/core/features/wireframe/wireframe-types';
import type { ServerResponse } from 'http';
import type { NextApiRequestCookies } from 'next/dist/server/api-utils';
import type { RequestCookies } from 'next/dist/server/web/spec-extension/cookies';
import type { NextResponse } from 'next/dist/server/web/spec-extension/response';
import type { NextRequest } from 'next/server';

import { IS_SERVER, NEXT_PUBLIC_COOKIE_DOMAIN, NEXT_PUBLIC_HOST_NAME } from '@/constants/env';
import { APP_SESSION_COOKIE_NAME } from '@/constants/product';
import { hasAllRequiredCookies } from '@/core/features/cookies/required-cookies';
import { logger } from '@/core/features/logger/logger';
import { isStaticFilePath } from '@/core/features/request/static-file-paths';
import { fromBase64, toBase64 } from '@/core/utils/base64';
import { parseSafe } from '@/core/utils/json';
import { TIME_1H } from '@/core/utils/time';

const defaultCookieConfig = {
    domain: NEXT_PUBLIC_COOKIE_DOMAIN,
    expires: '',
    isSecure: false,
    sameSite: 'Lax',
};
// order matters here
/* eslint-disable perfectionist/sort-objects */
export const DEVICEOUTPUT_RECORD = {
    desktop: 'desktop',
    mobile: 'mobile',
    app: 'app',
    tablet: 'tablet',
    tabletapp: 'tabletapp',
    DANGEROUS_android: 'android',
    DANGEROUS_ios: 'ios',
} as const;
/* eslint-enable perfectionist/sort-objects */

export const DEVICE_COOKIES_RECORD = {
    deviceinfo: 'deviceinfo',
    deviceoutput: 'deviceoutput',
    devicetype: 'devicetype',
};

type DeviceOSMapped = 'android' | 'ios' | 'other';

export type Deviceoutput = (typeof DEVICEOUTPUT_RECORD)[keyof typeof DEVICEOUTPUT_RECORD];

export type DeviceoutputMapped = 'desktop' | 'mobile';

export type CookiesUnmapped = Record<string, string>;

export type CookiesMapped = {
    bpm: null | string;
    c24appversion: null | string;
    c24cams: null | string;
    c24consent: WireframeCookieConsentType | null;
    c24sab: null | string;
    c24session: null | string;
    c24splash: null | string;
    c24webview: 'indi' | null; // only set by app when in indi webview
    csCode: null | string;
    deviceinfo: string;
    deviceoutput: Deviceoutput;
    devicetype: Deviceoutput;
    indiFeatureFlags: null | string;
    indiSession: null | string;
    indiSplash: null | string;
    playwrightContext: null | string;
    ppset: null | string;
    tid: null | string;
    wpset: null | string;
    xppset: null | string;
};

type CookieRequest = {
    debugNamespace: string;
    request?: { cookies: NextApiRequestCookies | RequestCookies };
    response?: ServerResponse;
};

export const getDeviceoutputFromCookies = (cookies?: CookiesMapped): Deviceoutput => {
    return cookies ? cookies.deviceoutput : 'mobile';
};

export const getDeviceoutputMapped = (deviceoutput: Deviceoutput): DeviceoutputMapped => {
    return deviceoutput === 'desktop' || deviceoutput?.includes('tablet') ? 'desktop' : 'mobile';
};

export const setNextCookie = (
    nextHttpObject: NextRequest | NextResponse | ServerResponse,
    name: string,
    value: string,
    {
        expires,
        isHttpOnly,
        isTopLevelDomain,
    }: {
        expires?: Date;
        isHttpOnly?: boolean;
        isTopLevelDomain: boolean;
    },
): void => {
    expires = expires ?? new Date(Date.now() + TIME_1H * 6);

    const domain = isTopLevelDomain ? NEXT_PUBLIC_COOKIE_DOMAIN : NEXT_PUBLIC_HOST_NAME;

    if ('cookies' in nextHttpObject) {
        nextHttpObject?.cookies?.set(name, value, {
            domain,
            expires,
            httpOnly: isHttpOnly,
            sameSite: 'lax',
            secure: true,
        });
        return;
    }

    nextHttpObject.setHeader(
        'Set-Cookie',
        `${name}=${value}; Domain=${domain}; ${isHttpOnly ? 'HttpOnly;' : ''} SameSite=Lax; Secure=true; Expires=${expires.toUTCString()}`,
    );
};

export const buildCookieTTL = (seconds: number): string => {
    let date = new Date();
    date.setSeconds(date.getSeconds() + seconds);
    return `expires=${date.toUTCString()}`;
};

type BrowserCookieConfig = {
    // domain?: string;
    expires?: string;
    isSecure?: boolean;
    isTopLevel: boolean;
    sameSite?: 'Lax' | 'None' | 'Strict';
};

export const setBrowserCookie = (cookieName: string, cookieValue: string, cookieConfig: BrowserCookieConfig): void => {
    const config = { ...defaultCookieConfig, ...cookieConfig };

    if (IS_SERVER) {
        return;
    }
    if (!config.domain) {
        logger.error(`NEXT_PUBLIC_COOKIE_DOMAIN is not set, domain: ${config.domain}`, { serverOnly: true });
        return;
    }
    try {
        const cookie = `${cookieName}=${cookieValue}`;
        const path = 'path=/';
        const domain = `domain=${config.isTopLevel ? NEXT_PUBLIC_COOKIE_DOMAIN : location.host}`;
        const secure = config.isSecure ? 'secure' : null;
        const sameSiteValue = `SameSite=${config.sameSite}`;
        const expires = config.expires || (cookieValue === '' ? buildCookieTTL(-100) : undefined);
        const cookieConfig = [cookie, expires, domain, path, secure, sameSiteValue].filter(Boolean).join('; ');

        // eslint-disable-next-line fp/no-mutation
        window.document.cookie = cookieConfig;
    } catch (e) {
        logger.error(`Could not set browser cookie ${cookieName}`);
        return;
    }
};

export const buildCompressedCookieValue = (cookieValue: object): string => toBase64(JSON.stringify(cookieValue));

export const getCompressedCookieValue = (
    cookieName: string,
    cookieValue: string,
    context: { c24appversion?: null | string } = {},
): object => {
    try {
        return JSON.parse(fromBase64(cookieValue));
    } catch (error) {
        logger.error(`Could not decompress cookie ${cookieName}`, {
            additionalData: `cookieValue: ${cookieValue}, error: ${error}`,
            c24appversion: context.c24appversion,
        });
        return {};
    }
};

export const getBrowserCookie = (cookieName: string): null | string => {
    if (IS_SERVER) {
        return null;
    }
    try {
        const cookies: string[] = window.document.cookie.split(';');
        let cookieValue = null;
        cookies.find((cookie) => {
            const [key, value] = cookie.trim().split('=');
            if (key === cookieName) {
                // eslint-disable-next-line fp/no-mutation
                cookieValue = value;
            }

            return key === cookieName;
        });

        return cookieValue;
    } catch (e) {
        logger.error(`Could not get browser cookie ${cookieName}, cookies: ${window.document.cookie}`);
        return null;
    }
};

export const hasBrowserCookie = (serverCookies: CookiesUnmapped, cookieName: string): boolean => {
    if (IS_SERVER) {
        return !!serverCookies[cookieName];
    }

    return window.document.cookie.indexOf(cookieName) > -1;
};

export const getDeviceInfo = (
    deviceinfoCookie: CookiesMapped['deviceinfo'],
    indiSession: CookiesMapped['indiSession'],
    iteration = 0,
): { deviceName: string; deviceOS: string; version: string } => {
    try {
        if (!deviceinfoCookie) {
            throw 'deviceinfoCookie is undefined';
        }
        if (deviceinfoCookie.includes('%7B%') && iteration === 0) {
            // handle as uri encoded json once
            return getDeviceInfo(decodeURIComponent(deviceinfoCookie), indiSession, iteration + 1);
        }
        const parsedDeviceinfoCookie = JSON.parse(deviceinfoCookie);
        return {
            deviceName: parsedDeviceinfoCookie['completeDeviceName'] ?? '',
            deviceOS: parsedDeviceinfoCookie['os'] ?? '',
            version: parsedDeviceinfoCookie['osVersion'] ?? '',
        };
    } catch (error) {
        logger.error(`Error while parsing device os: ${error}; is: ${deviceinfoCookie}`, { indiSession });
        return { deviceName: '', deviceOS: '', version: '' };
    }
};

export const isIosDevice = (
    deviceinfoCookie: CookiesMapped['deviceinfo'],
    indiSession: CookiesMapped['indiSession'],
): boolean => {
    const { deviceOS } = getDeviceInfo(deviceinfoCookie, indiSession);
    return getDeviceOSMapped(deviceOS) === 'ios';
};

export const isAndroidDevice = (
    deviceinfoCookie: CookiesMapped['deviceinfo'],
    indiSession: CookiesMapped['indiSession'],
): boolean => {
    const { deviceOS } = getDeviceInfo(deviceinfoCookie, indiSession);
    return getDeviceOSMapped(deviceOS) === 'android';
};

export function getDeviceOSMapped(deviceOS: string): DeviceOSMapped {
    switch (deviceOS) {
        case 'iOS':
        case 'iPadOS':
            return 'ios';
        case 'Android':
            return 'android';
        default:
            return 'other';
    }
}

export const getAllCookieValues = ({ request, response }: CookieRequest): Record<string, string> => {
    const allCookieValues: Record<string, string> = {};

    const responseCookies = response?.getHeader('set-cookie');

    /**
     * Warning: Order matters here!
     * Response cookies should be set last to have precedence
     *  as those were set by the middleware where deviceoutput override is handled
     */
    if (typeof request?.cookies?.getAll === 'function') {
        const cookies = request.cookies.getAll();
        cookies.forEach((cookie) => {
            allCookieValues[cookie.name] = cookie.value;
        });
    } else if (typeof request?.cookies === 'object') {
        Object.entries(request.cookies).forEach(([key, value]) => {
            if (!value) {
                return;
            }
            // eslint-disable-next-line fp/no-mutation
            allCookieValues[key] = value;
        });
    }

    if (Array.isArray(responseCookies)) {
        responseCookies.forEach((responseCookie) => {
            const [key, value] = responseCookie.split(';')[0]?.split('=') ?? [];
            if (!key || !value) {
                return;
            }
            // eslint-disable-next-line fp/no-mutation
            allCookieValues[key] = value;
        });
    }

    return allCookieValues;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getAllMappedCookieValuesFallback = (cookies: any = {}): CookiesMapped => {
    let deviceoutput: Deviceoutput = 'mobile';
    let devicetype: Deviceoutput = 'mobile';

    if (cookies.devicetype in DEVICEOUTPUT_RECORD) {
        devicetype = cookies.devicetype;
        deviceoutput = cookies.devicetype;
    }

    if (cookies.deviceoutput in DEVICEOUTPUT_RECORD) {
        deviceoutput = cookies.deviceoutput;
        if (!devicetype) {
            devicetype = cookies.deviceoutput;
        }
    }

    return {
        bpm: cookies.bpm || null,
        c24appversion: cookies.c24appversion || null,
        c24cams: cookies.c24cams || null,
        c24consent: cookies.c24consent || null,
        c24sab: cookies.c24sab || null,
        c24session: cookies.c24session || null,
        c24splash: cookies.c24splash || null,
        c24webview: cookies.c24webview || null,
        csCode: cookies.cs_code || null,
        deviceinfo: cookies.deviceinfo || JSON.stringify({}),
        deviceoutput,
        devicetype,
        indiFeatureFlags: null,
        indiSession: cookies.indiSession || null,
        indiSplash: cookies.indiSplash || null,
        playwrightContext: cookies.playwrightContext || null,
        ppset: cookies.ppset || null,
        tid: cookies.tid || null,
        wpset: cookies.wpset || null,
        xppset: cookies.xppset || null,
    };
};

export const getAllMappedCookieValues = (
    allCookies: Record<string, string> = {},
    url: string,
    debugNamespace: string,
    userAgent?: string,
    options?: { quiet?: boolean },
): CookiesMapped => {
    if (!hasAllRequiredCookies(allCookies)) {
        if (isStaticFilePath(url)) {
            return getAllMappedCookieValuesFallback(allCookies);
        }

        if (!options?.quiet) {
            logger.error('Cookie Service: not all cookies could be mapped correctly', {
                additionalData: JSON.stringify({
                    deviceinfo: allCookies.deviceinfo,
                    deviceoutput: allCookies.deviceoutput,
                    devicetype: allCookies.devicetype,
                }),
                debugNamespace,
                indiSession: allCookies[APP_SESSION_COOKIE_NAME],
                url,
                userAgent,
            });
        }

        return getAllMappedCookieValuesFallback(allCookies);
    }
    const {
        bpm = null,
        c24appversion = null,
        c24cams = null,
        c24consent = null,
        c24sab = null,
        c24session = null,
        c24splash = null,
        c24webview = null,
        cs_code: csCode = null,
        deviceinfo,
        deviceoutput,
        devicetype,
        indiFeatureFlags = null,
        indiSession = null,
        indiSplash = null,
        playwrightContext = null,
        ppset = null,
        tid = null,
        wpset = null,
        xppset = null,
    } = allCookies;

    return {
        bpm,
        c24appversion,
        c24cams,
        c24consent: c24consent as WireframeCookieConsentType | null,
        c24sab,
        c24session,
        c24splash,
        c24webview: c24webview === 'indi' ? c24webview : null,
        csCode,
        deviceinfo: deviceinfo ?? '{}',
        deviceoutput: deviceoutput as Deviceoutput,
        devicetype: (devicetype ?? deviceoutput) as Deviceoutput,
        indiFeatureFlags,
        indiSession,
        indiSplash,
        playwrightContext,
        ppset,
        tid,
        wpset,
        xppset,
    };
};

export const changeDeviceoutput = (deviceoutput: Deviceoutput) => {
    if (IS_SERVER) {
        throw 'Changing Deviceoutput on Server Side is not allowed';
    }
    const newUrl = new URL(location.href);
    newUrl.searchParams.set('deviceoutput', deviceoutput);
    // eslint-disable-next-line fp/no-mutation
    location.href = newUrl.toString();
};

export const getAppVersion = (cookiesMapped: CookiesMapped): string | undefined => {
    const { c24appversion } = cookiesMapped;

    if (!c24appversion || typeof c24appversion !== 'string') {
        return undefined;
    }
    const version = c24appversion.split('-').at(-1);

    return version ?? undefined;
};

export function isDeviceoutput(deviceoutput: string | undefined): deviceoutput is Deviceoutput {
    if (!deviceoutput) {
        return false;
    }
    return Object.values(DEVICEOUTPUT_RECORD).includes(deviceoutput as Deviceoutput);
}

export const getIndiFeatureFlags = (cookiesMapped: CookiesMapped): FeatureFlags => {
    return parseSafe(cookiesMapped.indiFeatureFlags);
};
