/* eslint-disable fp/no-mutation */
import type { LinkType } from '@/core/features/link/link';

import { IS_SERVER } from '@/constants/env';
import { devLogger } from '@/core/features/logger/logger';
import SessionStorage from '@/core/features/store/session-storage';
import { TIME_1H } from '@/core/utils/time';
import { getUrlPath } from '@/core/utils/url';

const LOGS_ENABLED = false;

export const logScrollRestorationEvent =
    IS_SERVER || !LOGS_ENABLED
        ? () => {}
        : // eslint-disable-next-line no-console
          (...message: (boolean | number | string | undefined)[]) =>
              devLogger.info(`Scroll Restoration Event ${message.join(' ')}`);

const scrollSessionStorage = new SessionStorage<
    Partial<{
        pageComponentIndex: number;
        pageMax: number;
        pageY: number;
        path: string;
        type: LinkType;
    }>
>('scroll', { defaultTTL: 2 * TIME_1H });

export const setScrollRestoration = (type: ScrollRestoration) => {
    if (!('scrollRestoration' in window.history)) {
        return;
    }

    if (window.history.scrollRestoration === type) {
        return;
    }

    logScrollRestorationEvent('setScrollRestoration', type);

    window.history.scrollRestoration = type;
};

export const removePageScrollPosition = (url: string) => {
    const path = getUrlPath(url);
    logScrollRestorationEvent('removePageScrollPosition', path);
    scrollSessionStorage.removeItem(path);
};

export const savePageScrollPosition = (url: string, { linkType = 'internal' }: { linkType?: LinkType } = {}) => {
    const scrollY = window.scrollY;

    if (scrollY < 100) {
        return removePageScrollPosition(url);
    }

    const path = getUrlPath(url);

    logScrollRestorationEvent('savePageScrollPosition', path, scrollY);

    scrollSessionStorage.setKey(path, { pageY: scrollY, path, type: linkType });
    savePageMaxScroll(path);
};

const scrollTop = () => {
    const scrollY = window.scrollY;

    if (scrollY === 0) {
        return;
    }

    logScrollRestorationEvent('Scroll back to 0');

    window.scrollTo({ top: 0 });
};

export const restorePageScrollPosition = (
    url: string,
    { hasTouchedRef }: { hasTouchedRef?: React.MutableRefObject<boolean> } = {},
) => {
    if (!('scrollRestoration' in window.history)) {
        return;
    }

    if (window.history.scrollRestoration !== 'manual') {
        return;
    }
    if (url.includes('buchung') || url.includes('checkout')) {
        scrollTop();
        return;
    }
    const path = getUrlPath(url);
    const isMaxBodyScrollHeightSet = setMaxScrollHeightOnBody(url);
    const store = scrollSessionStorage.getKey(path);

    if (!store || path !== store.path || !store.pageY) {
        logScrollRestorationEvent('No scroll position stored for', path);

        scrollTop();
        return;
    }

    const scrollY = window.scrollY;

    logScrollRestorationEvent('scrolling to', path, store.pageY, 'current:', scrollY);

    window.scrollTo({ top: store.pageY });

    // make sure scroll position is restored also for less performant devices unless user interacts
    let retryScrollTimeout: NodeJS.Timeout | null = null;
    const retryUrl = location.href;
    const retryScrollHandler = ({ retryCount = 0 } = {}) => {
        if (retryUrl !== location.href) {
            // abort retry on url changed
            return;
        }

        if (retryCount > 3) {
            return;
        }
        if (retryScrollTimeout !== null && hasTouchedRef?.current) {
            clearTimeout(retryScrollTimeout);
            return;
        }
        const scrollY = window.scrollY;
        if (scrollY !== store.pageY) {
            window.scrollTo({ top: store.pageY });
        } else {
            return;
        }

        retryScrollTimeout = setTimeout(() => {
            retryScrollHandler({ retryCount: retryCount + 1 });
        }, 1000);
    };

    setTimeout(() => {
        retryScrollHandler();
    }, 100);

    if (isMaxBodyScrollHeightSet) {
        /* 2600ms - accumulated delay of all previously set timeouts to ensure that the body minHeight is not reset too early. */
        unsetMaxScrollHeightOnBody(2600);
    }
};

export const savePageComponentIndex = (url: string, index: number) => {
    const path = getUrlPath(url);
    const current = scrollSessionStorage.getKey(path);

    if (current?.pageComponentIndex && current.pageComponentIndex > index) {
        return;
    }

    scrollSessionStorage.setKey(path, { ...current, pageComponentIndex: index });
};

export const getPageComponentIndex = (url: string): null | number => {
    return scrollSessionStorage.getKey(getUrlPath(url))?.pageComponentIndex ?? null;
};

/**
 * store the maximum scroll height the user ever had to set the body to this value
 * whenever a scroll restoration is needed so that we restore the scroll position
 * without the previous page blocking the scroll by its smaller height
 */
const savePageMaxScroll = (url: string) => {
    const path = getUrlPath(url);
    const store = scrollSessionStorage.getKey(path);
    const prevMaxScroll = store?.pageMax ?? 0;
    const scrollY = window.scrollY;

    const maxScroll = Math.max(prevMaxScroll, scrollY);

    if (maxScroll > prevMaxScroll) {
        logScrollRestorationEvent('Storing maxScroll', maxScroll);

        scrollSessionStorage.setKey(path, { ...store, pageMax: maxScroll });
    }
};

const setMaxScrollHeightOnBody = (url: string): boolean => {
    const store = scrollSessionStorage.getKey(getUrlPath(url));
    const maxScroll = store?.pageMax ?? 0;

    if (!maxScroll) {
        return false;
    }

    logScrollRestorationEvent('Set <body> maxScroll', maxScroll);

    if (document.querySelector('body')?.style.overflow === 'hidden') {
        return false;
    }
    // we add 2000px as buffer, also so that the footer is not visible during transition
    document.querySelector('body')?.setAttribute('style', `min-height: ${maxScroll + 2000}px`);

    return true;
};

const unsetMaxScrollHeightOnBody = (delay: number) => {
    logScrollRestorationEvent('Unset <body> maxScroll');
    setTimeout(() => {
        // timeout is needed here as rendering of previous page might take some time
        document.querySelector('body')?.removeAttribute('style');
    }, delay);
};
