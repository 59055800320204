import { QueryClient } from '@/core/features/react-query/react-query-service';
import { isAxiosError } from '@/core/features/request/axios';
import { isStaticFilePath } from '@/core/features/request/static-file-paths';

export const createQueryClient = (url: string) => {
    const isStaticPath = isStaticFilePath(url);

    return new QueryClient({
        defaultOptions: {
            queries: {
                enabled: !isStaticPath,
                retry: (failureCount, error) => {
                    if (isAxiosError(error)) {
                        const status = error.response?.status;

                        if (status && [301, 302, 403, 404, 405].includes(status)) {
                            return false;
                        }
                    }
                    return failureCount < 3;
                },
            },
        },
    });
};
