import React from 'react';

import { isUnhandledInternalLink } from '@/core/features/link/unhandled-internal-link-service';
import { triggerNativeAppMethod } from '@/core/features/native-app-bridge/native-app-bridge-service';

/**
 * Docs: https://c24-indi.atlassian.net/browse/INDI-2483
 */
const APP_NATIVE_LINK_PREFIX = 'appnative:';

export const willForwardToAppNative = (href: string): boolean => href.indexOf(APP_NATIVE_LINK_PREFIX) === 0;

export const forwardLinkUrlToAppNative = (event: MouseEvent | React.MouseEvent, href: string): void => {
    event.preventDefault();
    triggerAppNativeLink(href);
};

export const triggerAppNativeLink = (linkUrl: string): void => {
    triggerNativeAppMethod('triggerNativeNavigation', { linkUrl });
};

/**
 * API/CMS can provide plain html text links that are not rendered through our <Link> component.
 * Those links also can be appnative: links
 * Here we handle those links to forward them to the app native bridge
 */
export const useUnhandledInternalAppNativeLinkListener = ({ isApp }: { isApp: boolean }) => {
    React.useEffect(() => {
        if (!isApp) {
            return;
        }

        const clickListener = (event: MouseEvent) => {
            if (isUnhandledInternalLink(event.target) && willForwardToAppNative(event.target.href)) {
                forwardLinkUrlToAppNative(event, event.target.href);
            }
        };

        window.addEventListener('click', clickListener);

        return () => {
            window.removeEventListener('click', clickListener);
        };
    }, [isApp]);
};
