import type { SsrProps } from '@/core/features/app/ssr-props';

import React from 'react';

import { useHydrateMetaDataState } from '@/core/features/a-dynamic-page/hooks/meta-data-state';
import { useHydratePageComponentIndexSessionStorage } from '@/core/features/a-dynamic-page/hooks/use-dynamic-page-component-index';
import { createInitialAppAtomsStore } from '@/core/features/app/app-atoms';
import { AtomStoreProvider } from '@/core/features/store/atom-store';
import { useHydrateWireframeConsentBannerVisibleState } from '@/core/features/wireframe/wireframe-manipulation/consent-banner/use-wireframe-consent-banner-visible';

type AppContextWrapperProps = React.PropsWithChildren<SsrProps>;

export default function AppContextWrapper(props: AppContextWrapperProps) {
    const { children, ...ssrProps } = props;
    const initialAtomStore = createInitialAppAtomsStore(ssrProps);

    if (props.isStaticRoute) {
        return <AtomStoreProvider store={initialAtomStore}>{children}</AtomStoreProvider>;
    }

    return (
        <AtomStoreProvider store={initialAtomStore}>
            <HydrateDynamicPageAppContext {...props}>{children}</HydrateDynamicPageAppContext>
        </AtomStoreProvider>
    );
}

const HydrateDynamicPageAppContext = (props: AppContextWrapperProps) => {
    useHydrateMetaDataState({ defaultMeta: props.meta });
    useHydrateWireframeConsentBannerVisibleState(props.cookiesMapped, props.okResponse);
    useHydratePageComponentIndexSessionStorage();

    return <>{props.children}</>;
};
