import { IS_SERVER } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';

// https://confluence.check24.de/display/C24APPS/JavaScript+Interface#JavaScriptInterface-C24App.isPluginEnabled()
export const isAppPluginEnabled = (callback: (success: boolean) => void): void => {
    if (IS_SERVER) {
        return;
    }
    return window.C24App?.isPluginEnabled?.('de.check24.indi', callback);
};

export const triggerNativeAppMethod = (method: string, payload: Object): void => {
    if (IS_SERVER) {
        logger.error('Error: triggerNativeAppMethod should not be called on server');
        return;
    }

    try {
        /**
         * trigger default app bridge
         */
        if (window.C24App?.isiOSBridgeAvailable() && !!window.webkit?.messageHandlers?.appBridge) {
            window.webkit.messageHandlers.appBridge.postMessage({
                method,
                ...payload,
            });
        } else if (
            window.C24App?.isAndroidBridgeAvailable() &&
            typeof window.appBridge === 'object' &&
            window.appBridge?.hasOwnProperty(method)
        ) {
            window.appBridge[method]?.(payload);
        }

        /**
         * trigger indi app bridge
         */
        if (window.C24App?.isiOSBridgeAvailable() && !!window.webkit?.messageHandlers?.indiAppBridge) {
            window.webkit.messageHandlers.indiAppBridge.postMessage({
                method,
                ...payload,
            });
        }

        if (
            window.C24App?.isAndroidBridgeAvailable() &&
            typeof window.indiAppBridge === 'object' &&
            window.indiAppBridge.hasOwnProperty(method)
        ) {
            window.indiAppBridge[method]?.(JSON.stringify(payload));
        }
    } catch (error) {
        logger.error(`Error in triggerNativeAppMethod ${error}`);
    }
};
