import React from 'react';

import { IS_SERVER } from '@/constants/env';

/**
 * This wrapper will delay the script evaluation of the child component on client side as long as the condition is false
 * instead, the SSR rendered Component will be displayed as is
 * only when the condition becomes true, the component is rerendered client side and its JS evaluated.
 *
 * This component is useful to delay script evaluation and to decrease blocking time and TTI for performance
 * Note: a root tag to wrap is needed to prevent hydration errors
 */
export default function LazyHydrateOn(
    props: React.PropsWithChildren<{
        condition: boolean;
        htmlRootTag: 'div' | 'main'; // extend as needed and used
    }>,
): JSX.Element {
    if (props.condition || IS_SERVER) {
        return <>{props.children}</>;
    }

    return React.createElement(props.htmlRootTag, {
        dangerouslySetInnerHTML: { __html: '' },
        suppressHydrationWarning: true,
    });
}
