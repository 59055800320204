// https://nextjs.org/docs/api-reference/next/server#how-do-i-access-environment-variables
// process.env can be used to access Environment Variables from Middleware.
// These are evaluated at build time, so only environment variables actually used will be included.
// Any variables in process.env must be accessed directly, and cannot be destructured

// therefore we never destructure env vars

export const IS_SERVER = typeof window === 'undefined';
export const IS_NODE_ENV_DEV = process.env.NODE_ENV === 'development';

export const INDI_API_HOST = process.env.INDI_API_HOST;
export const NEXT_PUBLIC_HOST_NAME = process.env.NEXT_PUBLIC_HOST_NAME;
export const MEMCACHED_SERVERS = !!process.env.MEMCACHED_SERVERS && JSON.parse(process.env.MEMCACHED_SERVERS);
export const NEXT_PUBLIC_COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
export const INDI_MIDDLEWARE_HOST = process.env.INDI_MIDDLEWARE_HOST;
export const NEXT_PUBLIC_CHECK24_DOMAIN = process.env.NEXT_PUBLIC_CHECK24_DOMAIN;
export const IS_STAGING = !!NEXT_PUBLIC_HOST_NAME?.includes('individualreisen.check24-test.de');
export const IS_TBS = !!NEXT_PUBLIC_HOST_NAME?.includes('frontend-tbs');
export const IS_PROD = !!NEXT_PUBLIC_HOST_NAME?.includes('individualreisen.check24.de');

// Dev Utils
export const SHOW_HIDDEN_MENU = process.env.SHOW_HIDDEN_MENU === 'true';
export const X_INTERNAL_IP_SECRET = process.env.X_INTERNAL_IP_SECRET;
export const INDI_API_HOST_TEMPLATE = process.env.INDI_API_HOST_TEMPLATE;

// NEXT_PUBLIC_ indicates that this variable is accessible also in the client/browser
export const NEXT_PUBLIC_CDN_URL = process.env.NEXT_PUBLIC_CDN_URL;

// SSO
export const NEXT_PUBLIC_SSO_PRODUCT_ID = process.env.NEXT_PUBLIC_SSO_PRODUCT_ID;
export const NEXT_PUBLIC_SSO_ENV = process.env.NEXT_PUBLIC_SSO_ENV;
export const SSO_API_KEY = process.env.SSO_API_KEY;

// Google
export const NEXT_PUBLIC_GOOGLE_MAPS_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY;

// Tracking
export const NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME = process.env.NEXT_PUBLIC_GENERAL_TRACKING_PRODUCT_NAME;
