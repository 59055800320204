/* eslint-disable fp/no-mutation */
import type { SearchParams } from '@/core/utils/url';

import { NEXT_PUBLIC_CDN_URL } from '@/constants/env';
import { getURL } from '@/core/utils/url';

export const getCdnRawImageUrl = (src: string) => {
    const parsedUrl = getURL(src);
    parsedUrl.searchParams.set('v', '2');
    return parsedUrl.href;
};

const mergeCdnImageUrlParams = (url: string, width: number, height: number, queryParams: SearchParams): string => {
    const parsedUrl = getURL(url.replace('color=#', 'color=%23'));
    parsedUrl.pathname = `${parsedUrl.pathname}/${width}x${height}`;

    Object.entries(queryParams).forEach(([key, value]) => {
        if (!value) {
            parsedUrl.searchParams.delete(key);
            return;
        }
        parsedUrl.searchParams.set(key, value);
    });
    return parsedUrl.toString();
};

export const getCdnImageUrl = (src: string, width: number, height: number) => {
    // keep for later CDN testing when a new version is deployed
    // if (NEXT_PUBLIC_CHECK24_DOMAIN?.includes('test')) {
    // src = src.replace(NEXT_PUBLIC_CDN_URL, 'https://cdn-test.individualreisen.check24.de');
    // }

    if (src.includes('?')) {
        return mergeCdnImageUrlParams(src, width, height, { v: '2' });
    }

    return `${src}/${width}x${height}?v=2`;
};

export const getCdnStaticImageUrl = (imageUrl: string, size?: { height: number; width: number }) => {
    if (!NEXT_PUBLIC_CDN_URL) {
        throw 'NEXT_PUBLIC_CDN_URL is not defined in getCdnStaticImageUrl';
    }

    const cdnImageUrl = process.env.NEXT_PUBLIC_CDN_URL + imageUrl;

    if (!size) {
        return cdnImageUrl;
    }

    const { height, width } = size;
    return `${cdnImageUrl}/${width}x${height}`;
};
