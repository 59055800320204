import type { IconWrapperProps } from '@/core/components/icon/icon-wrapper';
import type { SVGIconProps } from '@/core/components/icon/svg-icon';

import { IconWrapper } from '@/core/components/icon/icon-wrapper';
import SVGIcon from '@/core/components/icon/svg-icon';

export default function WindowCloseThinIcon(props: SVGIconProps & IconWrapperProps) {
    return (
        <IconWrapper {...props}>
            <SVGIcon
                {...props}
                viewBox={'0 0 20 20'}
            >
                <path
                    d={
                        'm19.434 1.434-.868-.868a.8.8 0 0 0-1.132 0L10 8 2.566.566a.8.8 0 0 0-1.132 0l-.868.868a.8.8 0 0 0 0 1.132L8 10 .566 17.434a.8.8 0 0 0 0 1.132l.868.868a.8.8 0 0 0 1.132 0L10 12l7.434 7.434a.8.8 0 0 0 1.132 0l.868-.868a.8.8 0 0 0 0-1.132L12 10l7.434-7.434a.8.8 0 0 0 0-1.132Z'
                    }
                />
            </SVGIcon>
        </IconWrapper>
    );
}

export { WindowCloseThinIcon };
