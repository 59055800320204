import styles from './link.module.scss';
/**
 * Check if a link click is handled by our link component
 * or if it is a link that is coming from CMS content
 * in which case we treat it a an external linkout
 */
export const isUnhandledInternalLink = (target: EventTarget | null): target is HTMLAnchorElement => {
    if (!(target instanceof HTMLElement)) {
        return false;
    }

    const link = target.closest('a');

    if (!(link instanceof HTMLAnchorElement)) {
        return false;
    }

    if (link.getAttribute('class')?.includes(styles.link)) {
        return false;
    }

    if (link.getAttribute('target') === '_blank') {
        return false;
    }

    return true;
};
