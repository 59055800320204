export const JsonResponseHeaders = {
    'Content-Type': 'application/json; charset=utf-8',
};

export const JsonldResponseHeaders = {
    'Content-Type': 'application/ld+json; charset=utf-8',
};

export const JsonRequestHeaders = {
    Accept: 'application/json',
};

export const JsonldRequestHeaders = {
    Accept: 'application/ld+json',
};

export const C24SESSION_HEADER_NAME = 'x-c24session';
export const C24SESSION_HEADER_TEST_VALUE_EXISTING_USER = '1234';
export const C24SESSION_HEADER_TEST_VALUE_NEW_USER = '4321';

export const PREFETCH_REQUEST_HEADER_NAME = 'x-indi-prefetch';
export const PREFETCH_REQUEST_HEADER = { [PREFETCH_REQUEST_HEADER_NAME]: '1' }; // value does not matter
export const REQUEST_TRACKING_HEADER_NAME = 'x-indi-visit';
export const REQUEST_TRACKING_HEADER = { [REQUEST_TRACKING_HEADER_NAME]: '1' }; // value does not matter
export const CLIENT_CACHE_REFETCH_HEADER_NAME = 'x-indi-cache-refetch';
export const CLIENT_CACHE_REFETCH_HEADER = { [CLIENT_CACHE_REFETCH_HEADER_NAME]: '1' }; // value does not matter
export const INTERNAL_IP_HEADER_NAME = 'x-internal-ip';

export const DEBUG_TOKEN_HEADER_NAME = 'x-debug-token';

export const INDI_CLIENT_VERSION_HEADER_NAME = 'x-indi-version';
export const INDI_CLIENT_VERSION_HEADER = { [INDI_CLIENT_VERSION_HEADER_NAME]: '24' };
