import { logger } from '@/core/features/logger/logger';
import { getLastElement } from '@/core/utils/array';
import { removeAllSearchParams } from '@/core/utils/url';

export function getComponentNamedId(componentIri: string, componentType: string) {
    try {
        /* Extracts componentIri from @id */
        const id = removeAllSearchParams(getLastElement(componentIri.split('/')) ?? '');
        const type = componentType;
        return `${type}_c${id}`;
    } catch (error) {
        logger.error(`Error while creating componentNamedId for @id ${componentIri}.`, {
            serverOnly: true,
        });
        return componentType;
    }
}

export const getComponentId = (componentIri: string): string => {
    return removeAllSearchParams(getLastElement(componentIri.split('/')) ?? '');
};
