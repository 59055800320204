import React from 'react';

import debounce from 'lodash.debounce';

import { IS_SERVER } from '@/constants/env';

type Config = {
    debounceTimer?: number;
    element?: HTMLElement | Window | null;
    isTouchSensitive?: boolean; // if the user "holds" the element via touch, it will be considered scrolling
    unlockTimer?: number;
};
export default function useIsScrolling(config: Config = {}) {
    const {
        debounceTimer = 20,
        element = IS_SERVER ? undefined : window,
        isTouchSensitive = false,
        unlockTimer = 300,
    } = config;

    const [isScrolling, setScrolling] = React.useState(false);
    const [isTouching, setTouching] = React.useState(false);

    const isRegisteredRef = React.useRef(false);

    const prevScrollPosRef = React.useRef(0);

    const debouncedClearEventHandler = React.useCallback(() => {
        return debounce(
            function handleClear() {
                const scrollY = element instanceof Window ? element.scrollY : element?.scrollTop ?? 0;

                if (prevScrollPosRef.current !== scrollY) {
                    prevScrollPosRef.current = scrollY;
                    debouncedClearEventHandler()();
                    return;
                }
                prevScrollPosRef.current = scrollY;

                setScrolling(false);
            },
            unlockTimer,
            { trailing: true },
        );
    }, [unlockTimer, element]);

    const debouncedScrollHandler = React.useCallback(() => {
        return debounce(
            function handleScroll() {
                setScrolling(true);
            },
            debounceTimer,
            { leading: true },
        );
    }, [debounceTimer]);

    const setTouchActiveHandler = React.useCallback(
        (state: boolean) => () => {
            setTouching(state);
        },
        [],
    );

    React.useEffect(() => {
        if (isRegisteredRef.current || !element) {
            return;
        }
        isRegisteredRef.current = true;
        element.addEventListener('scroll', debouncedScrollHandler());
        element.addEventListener('scroll', debouncedClearEventHandler());

        if (isTouchSensitive) {
            element.addEventListener('touchstart', setTouchActiveHandler(true));
            element.addEventListener('touchend', setTouchActiveHandler(false));
        }

        return () => {
            element.removeEventListener('scroll', debouncedScrollHandler());
            element.removeEventListener('scroll', debouncedClearEventHandler());
            if (isTouchSensitive) {
                element.removeEventListener('touchstart', setTouchActiveHandler(true));
                element.removeEventListener('touchend', setTouchActiveHandler(false));
            }
        };
    }, [debouncedScrollHandler, debouncedClearEventHandler, element, isTouchSensitive, setTouchActiveHandler]);

    return isScrolling || (isTouchSensitive ? isTouching : false);
}
