import React from 'react';

import WindowCloseThinIcon from '@/assets/icons/window-close-thin';
import { Button, Image, ResponsiveImage } from '@/core/features';
import { getCdnStaticImageUrl } from '@/core/features/image-v2/image-utils';
import color from '@/core/features/styles/color';
import zIndex from '@/core/features/styles/z-index';

import styles from './app-splash.module.scss';

type AppSplashProps = {
    ctaLink: string;
    onClose: () => void;
};

export default function AppSplash({ ctaLink, onClose }: AppSplashProps) {
    return (
        <div
            className={styles.appSplash}
            style={{ zIndex: zIndex('appSplash') }}
        >
            <ResponsiveImage
                alt={'Check24 Deutschlands beste Reisen App'}
                className={'height-100'}
                forceHydrate={true}
                height={'full'}
                layoutWidth={100}
                objectFit={'cover'}
                preload={true}
                url={getCdnStaticImageUrl('/static-images/app-splash.jpg')}
            />
            <button
                className={`flex-center ${styles.close}`}
                data-qa-id={'qa-app-splash-close'}
                onClick={onClose}
            >
                <WindowCloseThinIcon
                    color={color('white')}
                    height={20}
                    width={20}
                />
            </button>
            <div className={`flex-center ${styles.plectrum}`}>
                <Image
                    alt={''}
                    forceHydrate={true}
                    height={88}
                    objectFit={'contain'}
                    preload={true}
                    url={getCdnStaticImageUrl('/static-images/plektrum.svg')}
                    width={110}
                />
                <div className={styles.plectrumText}>
                    Jetzt <span className={'bold'}>doppelt punkten</span>
                </div>
            </div>
            <div className={`absolute-full ${styles.bottomContainer}`}>
                <div className={styles.splashText}>
                    Deutschlands <span className={'bold'}>beste Reisen App</span>
                </div>
                <Button
                    href={ctaLink}
                    linkType={'vanilla'}
                    title={'gratis herunterladen'}
                />
                <div className={`flex-center ${styles.appStores}`}>
                    <Image
                        alt={'Google Play'}
                        forceHydrate={true}
                        height={34}
                        preload={true}
                        url={getCdnStaticImageUrl('/static-images/google-play.svg')}
                        width={115}
                    />
                    <Image
                        alt={'App Store'}
                        forceHydrate={true}
                        height={34}
                        preload={true}
                        url={getCdnStaticImageUrl('/static-images/app-store.svg')}
                        width={115}
                    />
                </div>
            </div>
        </div>
    );
}
