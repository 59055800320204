import React from 'react';

import { isDynamicPageRedirectErrorResponse } from '@/core/features/a-dynamic-page/services/dynamic-page-service';
import { useRouterLink } from '@/core/features/router/router-link';

export default function useDynamicPageRedirect(error: Error | null) {
    const { navigate } = useRouterLink();

    React.useEffect(() => {
        if (isDynamicPageRedirectErrorResponse(error)) {
            const { redirectUrl } = error.response.data;

            navigate(redirectUrl, { historyType: 'historyReplace' });
        }
    }, [error, navigate]);
}
