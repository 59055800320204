import type {
    NestedPageComponent,
    PageComponent,
} from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { ComponentRequestParameters, DynamicPageError } from '@/core/features/a-dynamic-page/dynamic-page-types';

import { getComponentId } from '@/core/features/a-component/services/component-id-service';
import { isDeviceoutput } from '@/core/features/cookies/cookies-service';
import { logger } from '@/core/features/logger/logger';
import { clientSideApiGetRequest } from '@/core/features/request/client-side-request';
import { isEmptyObject } from '@/core/utils/object';
import { getURL, getUrlPathWithSearch, urlSearchParamsToObject } from '@/core/utils/url';

const INDI_API_PATH_COMPONENT = 'component';
const NEXT_API_PATH_COMPONENT = '/components';

export const getComponentQueryKey = (componentIri: string): string[] => {
    const search = getURL(componentIri).search;

    const additionalParams = [...(componentIri.includes('fromLongitude') ? ['boundaries'] : [])];

    return [
        INDI_API_PATH_COMPONENT,
        ...additionalParams,
        getComponentId(componentIri),
        ...(search.length ? [decodeURIComponent(search)] : []),
    ];
};

export const getPaginatedComponentQueryKey = (componentIri: string): string[] => {
    return ['pagniated', ...getComponentQueryKey(componentIri)];
};

export const getComponentClientSide = async <ResponseType>(
    componentId: string,
    searchParams: URLSearchParams,
    abortSignal?: AbortSignal,
) => {
    const requestUrl = getComponentApiRequestUrl(componentId, searchParams);

    const response = await clientSideApiGetRequest<ResponseType>(requestUrl, abortSignal);

    return response.data;
};

const getComponentApiRequestUrl = (componentId: string, searchParams: URLSearchParams): string => {
    return getUrlPathWithSearch(`${NEXT_API_PATH_COMPONENT}/${componentId}?${searchParams.toString()}`);
};

export const isPageComponentResponse = (response: unknown): response is PageComponent => {
    return !!response && !!(response as PageComponent).attributes;
};

export const isPageComponentError = (response: unknown): response is DynamicPageError => {
    return !!response && (response as DynamicPageError).code !== undefined;
};

export const getComponentRequestParameters = (parsedUrl: URL): ComponentRequestParameters => {
    const { additionalParameters, deviceoutput, userQuery } = urlSearchParamsToObject(parsedUrl.searchParams);
    const userQueryString = userQuery;
    const additionalParametersString = additionalParameters;
    const componentId = getComponentId(parsedUrl.pathname);
    return {
        additionalParametersString,
        componentId,
        deviceoutput: isDeviceoutput(deviceoutput) ? deviceoutput : undefined,
        userQueryString,
    };
};

export const updateComponentIriParam = (
    paramName: 'additionalParameters' | 'userQuery',
    componentIri: string,
    addedParamValue?: Record<string, null | object | string | undefined>,
): URL => {
    const newIri = getURL(componentIri);
    try {
        if (addedParamValue) {
            const iriSearchParams = urlSearchParamsToObject(newIri.searchParams);
            if (paramName in iriSearchParams) {
                const paramValue = JSON.parse(iriSearchParams[paramName] ?? '');
                newIri.searchParams.set(paramName, JSON.stringify({ ...paramValue, ...addedParamValue }));
            } else {
                newIri.searchParams.set(paramName, JSON.stringify(addedParamValue));
            }
        }

        const paramValue = newIri.searchParams.get(paramName);

        if (!paramValue) {
            return newIri;
        }

        if (isEmptyObject(JSON.parse(paramValue))) {
            newIri.searchParams.delete(paramName);
        }

        return newIri;
    } catch (error) {
        logger.error(`Could not update ${paramName} in updateComponentIriParam.`, {
            url: componentIri,
        });
        return newIri;
    }
};

export const isNestedComponent = (component: PageComponent): component is NestedPageComponent => {
    return component['@type'] === 'GroupV1';
};
