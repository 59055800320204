export const getLastElement = <T>(arr: T[]): T | undefined => {
    return arr[arr.length - 1];
};

export const updateElementAtIndex = <T>(arr: T[], index: number, updateValue: T) => {
    return arr.map((element, elementIndex) => (index === elementIndex ? updateValue : element));
};

export const insertElementAt2DArrayIndex = <T>(arr: T[][], index: number, toAddValue: T) => {
    return arr.map((element, elementIndex) => (index === elementIndex ? [...element, toAddValue] : element));
};
