import React from 'react';

import styles from './svg-icon.module.scss';

export type SVGIconProps = React.PropsWithChildren<{
    color?: string;
    height?: number;
    hoverColor?: string;
    qaId?: string;
    rotate?: number;
    stroke?: string;
    strokeWidth?: number;
    viewBox?: string;
    width?: number;
}>;

export default function SVGIcon(props: SVGIconProps) {
    const {
        children,
        color = 'currentColor',
        height = 24,
        hoverColor,
        qaId,
        rotate,
        stroke,
        strokeWidth = 1,
        viewBox,
        width = 24,
    } = props;

    const iconColor = {
        '--fillColor': `${hoverColor ?? color}`,
    };

    return (
        <svg
            data-qa-id={qaId}
            style={{
                ...iconColor,
                height,
                stroke,
                strokeWidth,
                transform: rotate ? `rotate(${rotate}deg)` : undefined,
            }}
            version={'1.1'}
            viewBox={viewBox}
            width={width}
        >
            <g
                className={styles.hoverItem}
                fill={color}
            >
                {children}
            </g>
        </svg>
    );
}
