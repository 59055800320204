/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-this */

import { IS_SERVER } from '@/constants/env';
import { logger } from '@/core/features/logger/logger';

type ItemWithTTL<T> = {
    ttl?: number;
    value: T;
};

type NoopServerStorage = {
    getItem: (key: string) => null;
    removeItem: (key: string) => void;
    setItem: (key: string) => void;
};

type StorageType = NoopServerStorage | typeof localStorage | typeof sessionStorage;

export type BrowserStorageOptions = {
    defaultKey?: string;
    // Seconds
    defaultTTL?: number;
};

const versionPrefix = '__indi_V4_';

/* eslint-disable fp/no-class */
export default class BrowserStorage<T> {
    private readonly defaultKey?: string;
    private readonly defaultTTL?: number;
    private readonly storage: StorageType;
    private readonly storeName: string;

    constructor(storage: 'localStorage' | 'sessionStorage', storeName: string, options?: BrowserStorageOptions) {
        this.storage = BrowserStorage.getStorage(storage);
        this.storeName = storeName;
        this.defaultKey = options?.defaultKey;
        this.defaultTTL = options?.defaultTTL;
    }

    static getStorage(storage: 'localStorage' | 'sessionStorage'): StorageType {
        if (IS_SERVER || !this.isSupported(localStorage) || !this.isSupported(sessionStorage)) {
            return { getItem: (_key: string) => null, removeItem: (_key: string) => {}, setItem: (_key: string) => {} };
        }
        if (storage === 'localStorage') {
            return localStorage;
        }
        return sessionStorage;
    }

    static isSupported(storage: StorageType): boolean {
        if (IS_SERVER) {
            return false;
        }

        return !!storage;
    }

    private getStorageKey(key: string): string {
        if (key === '') {
            return `${versionPrefix}_${this.storeName}`;
        }
        return `${versionPrefix}_${this.storeName}_${key}`;
    }

    private parse(item: string): ItemWithTTL<T> {
        try {
            return JSON.parse(item);
        } catch (error) {
            logger.error(`Storage parsing error: ${error}`);
            throw error;
        }
    }

    private stringify(value: ItemWithTTL<T> | T): string {
        return JSON.stringify(value);
    }

    get(): T | null {
        if (!this.defaultKey && this.defaultKey !== '') {
            logger.error(
                `${this.storeName}: Storage method get is not supported when no defaultKey is defined in constructor.`,
            );
            return null;
        }

        return this.getKey(this.defaultKey);
    }

    getKey(key: string): T | null {
        const storageKey = this.getStorageKey(key);
        const item = this.storage.getItem(storageKey);

        if (item === null) {
            return null;
        }
        if (typeof item !== 'string') {
            logger.error(`Storage item ${storageKey} (${typeof item}) is not a string`);
            return null;
        }

        const parsedItem = this.parse(item);

        if (parsedItem.ttl === undefined) {
            return parsedItem.value ?? null;
        }

        if (parsedItem.ttl < Date.now()) {
            this.storage.removeItem(storageKey);
            return null;
        }

        return parsedItem.value ?? null;
    }

    remove(): void {
        if (!this.defaultKey && this.defaultKey !== '') {
            logger.error(
                `${this.storeName}: Storage method remove is not supported when no defaultKey is defined in constructor.`,
            );
            return;
        }
        return this.removeItem(this.defaultKey);
    }

    removeItem(key: string): void {
        const storageKey = this.getStorageKey(key);
        this.storage.removeItem(storageKey);
    }

    set(value: T, ttl = this.defaultTTL): void {
        if (!this.defaultKey && this.defaultKey !== '') {
            logger.error(
                `${this.storeName}: Storage method set is not supported when no defaultKey is defined in constructor.`,
            );
            return;
        }

        return this.setKey(this.defaultKey, value, ttl);
    }

    setKey(key: string, value: T, ttl = this.defaultTTL): void {
        const item: ItemWithTTL<T> | T = {
            ttl: ttl ? Date.now() + ttl * 1000 : undefined,
            value,
        };

        const storageKey = this.getStorageKey(key);
        this.storage.setItem(storageKey, this.stringify(item));
    }
}
