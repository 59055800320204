import { logger } from '@/core/features/logger/logger';

export const removeUndefinedAndNullValues = <T extends Object>(object: T): T | undefined => {
    Object.keys(object).forEach((key) => {
        // @ts-ignore
        if (object[key] === undefined || object[key] === null) {
            // @ts-ignore
            // eslint-disable-next-line fp/no-delete
            delete object[key];
        }
    });

    if (Object.keys(object).length === 0) {
        return undefined;
    }

    return object;
};

export const deepEquals = (object1: Object | null, object2: Object | null): boolean => {
    if (object1 === null || object2 === null) {
        return object1 === object2;
    }
    return JSON.stringify(object1) === JSON.stringify(object2);
};

export const isObject = (object: unknown): object is Object => {
    return !!object && typeof object === 'object';
};

export const isEmptyObject = (object: Object | null | undefined): boolean => {
    return !object || !Object.values(object).length;
};

export const objectStringToObject = (objectString: string): Record<string, string> | undefined => {
    try {
        const object = JSON.parse(objectString);

        if (isEmptyObject(object)) {
            return undefined;
        }

        return object;
    } catch (error) {
        logger.error('Unable to parse parametersString', {
            additionalData: JSON.stringify({ error: `${error}`, objectString }),
        });
        return undefined;
    }
};
