import type { OnClickEvent } from '@/core/types/react-overrides';

import React from 'react';

export type IconWrapperProps = React.PropsWithChildren<{
    className?: string;
    onClick?: (event: OnClickEvent) => void;
}>;

export function IconWrapper({ children, className, onClick }: React.PropsWithChildren<IconWrapperProps>) {
    return (
        <div
            className={`flex-center ${className ? className : ''}`}
            onClick={onClick}
        >
            {children}
        </div>
    );
}
